import {SubcomponentsButtonComponent, SubcomponentsLinkComponent} from 'lib/api/strapi';

import {NextLink, TNextLinkProps} from '../NextLink/Link';

export interface IStrapiLinkProps extends Omit<TNextLinkProps, 'href'> {
  linkProps?: SubcomponentsLinkComponent | null;
  buttonProps?: SubcomponentsButtonComponent | null;
}

export const StrapiLink = ({
  children,
  linkProps,
  buttonProps,
  ...nextLinkProps
}: IStrapiLinkProps) => {
  const {link = '', linkText, page, newWindow} = linkProps || {};
  const {buttonLink, page: buttonPage, newWindow: buttonNewWindow} = buttonProps || {};

  if (buttonProps) {
    const href = buttonPage.data?.attributes?.fullSlug || buttonLink || '';

    return (
      <NextLink {...nextLinkProps} target={buttonNewWindow ? '_blank' : '_self'} href={href}>
        {children}
      </NextLink>
    );
  }

  const href = page?.data?.attributes?.fullSlug || link || '';
  return (
    <NextLink {...nextLinkProps} target={newWindow ? '_blank' : '_self'} href={href}>
      {children || linkText}
    </NextLink>
  );
};

export default StrapiLink;
